import { render, staticRenderFns } from "./transcriptList.vue?vue&type=template&id=2a338051&scoped=true&"
import script from "./transcriptList.vue?vue&type=script&lang=js&"
export * from "./transcriptList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a338051",
  null
  
)

export default component.exports